import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle `
* {
    box-sizing: border-box;
}

*,*::before,*::after,h1, h2, h3, h4, h5, h6 {
    margin: 0:
    padding: 0;
}

h1, h2, h3, h4, h5, h6 {
    display: inline-block;
}

body {
    background-color: #040720;
    margin: 0 0;
    padding: 0;
    font-family: 'Myriad Pro', sans-serif;
}
`

export default GlobalStyle