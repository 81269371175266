// Primary Libraries //
import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import { motion } from 'framer-motion'

// Styled Classes //
const Page = styled(motion.div) `
background-color: #040720;
height: 100vh;
width: 100vw;
display: flex;
justify-content: center;
align-items: center;
z-index: 10;
`

const PreLoader = styled.div `
color: #FFFFFF;
height: 100%;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
font-family: 'Myriad Pro', sans-serif;
font-size: 1.2rem;
font-weight: 100;
z-index: 1000;
`

const LogoContainer = styled(motion.div) `
padding-bottom: 15rem;
position: fixed;
display: flex;
font-size: 10.75rem;
font-weight: 600;
font-family: 'Myriad Pro', sans-serif;
z-index: 3;

@media (max-width: 80em) {
    padding-bottom: 10rem;
    font-size: 7rem;
}

@media (max-width: 530px) {
    padding-bottom: 8rem;
    font-size: 6rem;
}

@media (max-width: 26em) {
    padding-bottom: 7rem;
    font-size: 5rem;
}
`

const Logo = styled(motion(NavLink)) `
position: relative;
color: ${props => props.theme.text};
text-decoration: none;
`

const LogoSpan = styled.span `
font-family: 'Myriad Pro', sans-serif;
font-weight: 700;
`

const SphereContainer = styled.div `
position: fixed;
justify-content: center;
align-items: center;
opacity: 1;
z-index: 3;
`

const RedSphere = styled(motion.div) `
height: 85px;
width: 85px;
margin-right: 1rem;
background-color: #FF0000;
border-radius: 50%;
display: inline-block;

@media (max-width: 80rem) {
    height: 50px;
    width: 50px;
}

@media (max-width: 530px) {
    height: 41px;
    width: 41px;
}

@media (max-width: 26rem) {
    height: 32px;
    width: 32px;
}
`

const OrangeSphere = styled(motion.div) `
height: 85px;
width: 85px;
margin-right: 1rem;
background-color: #FFA500;
border-radius: 50%;
display: inline-block;

@media (max-width: 80rem) {
    height: 50px;
    width: 50px;
}

@media (max-width: 530px) {
    height: 41px;
    width: 41px;
}

@media (max-width: 26rem) {
    height: 32px;
    width: 32px;
}
`

const YellowSphere = styled(motion.div) `
height: 85px;
width: 85px;
margin-right: 1rem;
background-color: #FFFF00;
border-radius: 50%;
display: inline-block;

@media (max-width: 80rem) {
    height: 50px;
    width: 50px;
}

@media (max-width: 530px) {
    height: 41px;
    width: 41px;
}

@media (max-width: 26rem) {
    height: 32px;
    width: 32px;
}
`

const GreenSphere = styled(motion.div) `
height: 85px;
width: 85px;
margin-right: 1rem;
background-color: #00FF00;
border-radius: 50%;
display: inline-block;

@media (max-width: 80rem) {
    height: 50px;
    width: 50px;
}

@media (max-width: 530px) {
    height: 41px;
    width: 41px;
}

@media (max-width: 26rem) {
    height: 32px;
    width: 32px;
}
`

const BlueSphere = styled(motion.div) `
height: 85px;
width: 85px;
margin-right: 1rem;
background-color: #0000FF;
border-radius: 50%;
display: inline-block;

@media (max-width: 80rem) {
    height: 50px;
    width: 50px;
}

@media (max-width: 530px) {
    height: 41px;
    width: 41px;
}

@media (max-width: 26rem) {
    height: 32px;
    width: 32px;
}
`

const IndigoSphere = styled(motion.div) `
height: 85px;
width: 85px;
margin-right: 1rem;
background-color: #4B0082;
border-radius: 50%;
display: inline-block;

@media (max-width: 80rem) {
    height: 50px;
    width: 50px;
}

@media (max-width: 530px) {
    height: 41px;
    width: 41px;
}

@media (max-width: 26rem) {
    height: 32px;
    width: 32px;
}
`

const PurpleSphere = styled(motion.div) `
height: 85px;
width: 85px;
background-color: #6A0DAD;
border-radius: 50%;
display: inline-block;

@media (max-width: 80rem) {
    height: 50px;
    width: 50px;
}

@media (max-width: 530px) {
    height: 41px;
    width: 41px;
}

@media (max-width: 26rem) {
    height: 32px;
    width: 32px;
}
`

const ComingSoon = styled(motion.h2) `
padding-top: 20rem;
position: fixed;
display: flex;
font-size: 4rem;
font-family: 'Myriad Pro', sans-serif;
font-weight: 500;
z-index: 3;
color: ${props => props.theme.text};
text-decoration: none;

@media (max-width: 80em) {
    padding-top: 15rem;
    font-size: 2.5rem;
}

@media (max-width: 530px) {
    padding-top: 12rem;
    font-size: 2rem;
}

@media (max-width: 26em) {
    padding-top: 10rem;
    font-size: 2rem;
}
`

// Animation Configurations //
const LogoAnimation = {
    hidden: {
        opacity: 0,
        y: 100,
        delay: 1.2
    },
    visible: {
        opacity: 1,
        y: 0,
        transition: { delay: 1.8, duration: 0.9, ease: 'easeInOut' }
    },
    exit: {
       opacity: 0,
       transition: { duration: 0.9, ease: 'easeInOut' },
    }
}

const SphereAnimation = {
    hidden: {
        opacity: 0
      },
      visible: {
        opacity: 1,
        transition: { duration: 1 }
      },
      exit: {
        opacity: 0
      }
}

const ComingSoonAnimation = {
    hidden: {
        opacity: 0,
        delay: 1.2
    },
    visible: {
        opacity: 1,
        transition: { delay: 1.8, duration: 2 }
    },
    exit: {
       opacity: 0,
       transition: { ease: 'easeInOut' },
    }
}

// Chakra.tv Home Page Export //
const ChakraTV = () => {
    const [loader, setLoader] = useState(true)
    useEffect(() => {
        setTimeout(() => {
            setLoader(false)
        }, 2000)
    })
    
    return (
        <Page>
            {loader ? (<PreLoader>Tuning In...</PreLoader>) : (
            <>
                <LogoContainer variants={LogoAnimation} initial="hidden" animate="visible" exit="exit" >
                    <Logo to="/" ><LogoSpan>chakra</LogoSpan>.tv</Logo>
                </LogoContainer>

                <SphereContainer>
                    <RedSphere variants={SphereAnimation} initial="hidden" animate="visible" exit={{opacity: 0, transition: { duration: 1, delay: 0.38 }}} whileHover={{ scale: [1, 1.1, 1.05]}} />
                    <OrangeSphere variants={SphereAnimation} initial="hidden" animate={{opacity: 1, transition: { duration: 1, delay: 0.2 }}} exit={{opacity: 0, transition: { duration: 1, delay: 0.3125 }}} whileHover={{ scale: [1, 1.1, 1.05]}} />
                    <YellowSphere variants={SphereAnimation} initial="hidden" animate={{opacity: 1, transition: { duration: 1, delay: 0.4 }}} exit={{opacity: 0, transition: { duration: 1, delay: 0.25 }}} whileHover={{ scale: [1, 1.1, 1.05]}} />
                    <GreenSphere variants={SphereAnimation} initial="hidden" animate={{opacity: 1, transition: { duration: 1, delay: 0.6 }}} exit={{opacity: 0, transition: { duration: 1, delay: 0.1875 }}} whileHover={{ scale: [1, 1.1, 1.05]}} />
                    <BlueSphere variants={SphereAnimation} initial="hidden" animate={{opacity: 1, transition: { duration: 1, delay: 0.8 }}} exit={{opacity: 0, transition: { duration: 1, delay: 0.125 }}} whileHover={{ scale: [1, 1.1, 1.05]}} />
                    <IndigoSphere variants={SphereAnimation} initial="hidden" animate={{opacity: 1, transition: { duration: 1, delay: 1.0 }}} exit={{opacity: 0, transition: { duration: 1, delay: 0.0625 }}} whileHover={{ scale: [1, 1.1, 1.05]}} />
                    <PurpleSphere variants={SphereAnimation} initial="hidden" animate={{opacity: 1, transition: { duration: 1, delay: 1.2 }}} exit={{opacity: 0, transition: { duration: 1 }}} whileHover={{ scale: [1, 1.1, 1.05]}} />
                </SphereContainer>

                <ComingSoon variants={ComingSoonAnimation} initial="hidden" animate="visible" exit="exit">Coming Soon</ComingSoon>
            </>
            )}
        </Page>
    )
}

export default ChakraTV