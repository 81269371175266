// Primary Libraries //
import { Route, Switch } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import { AnimatePresence } from 'framer-motion'

// Components //
import GlobalStyle from './GlobalStyle.tsx'
import ChakraTV from './Channels/ChakraTv/Components/ChakraTV.tsx'
import { primaryTheme } from './Channels/Crystals/Components/Themes.tsx'

// Crystals Web App Export //
function App() {
  const location = useLocation()

  return <>
    <GlobalStyle />
      <ThemeProvider theme={primaryTheme}>
        <AnimatePresence exitBeforeEnter>
            <Switch location={location} key={location.pathname}>
              <Route exact path="/" component={ChakraTV}/>
            </Switch>
        </AnimatePresence>
      </ThemeProvider>
  </>  
}

export default App

